body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 238, 238) !important;
}
.servicesDropDownHeading{
      color: #0070c9;
        line-height: 0em;
        font-size: 14px;
        margin-top: 5px;
        letter-spacing: -1px;
}

.autocompleteDropdownSearch{
      background-color: #f4f8fc;
}

.autocompleteDropdownSearch>div>div>input {
      padding: 2px !important;
}

.mr-10{
  margin-right: 10px;
}

.chat__container .header {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.541);
}

.chat__container .chat__section {
  padding: 2rem 0;
}

.chat__container .message__container {
  display: flex;
  gap: 1rem;
  padding-bottom: 1.3rem;
}

.chat__container .message__container .left .message {
  background-color: #e6edf5;
  border-radius: 5px;
  padding: 0.8rem 1.5rem;
  font-size: 14px;
}

.chat__container .message__container .left .info {
  font-size: 12px;
  color: gray;
}

.chat__container .message__container .left .info span {
  font-weight: 700;
  font-size: 15px;
  color: black;
}

.chat__container .message__container .right .account__img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.text-center{
  text-align: center;
}

.justify-content-end{
  justify-content: end!important;
}

/* Default container styles */
.containerForms {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}



@media (max-width: 768px) {
  .containerForms {
    max-width: 768px;
  }


}

@media (max-width: 576px) {
  .containerForms {
    max-width: 576px;
  }

}

.users__container {
  padding: 0 25px;
}
.displayFlex{
  display: flex;
  align-items: center;
  margin-right: 10px;
}