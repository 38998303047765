.banner-heading {
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading-title {
  margin: 0;
  font-size: 34px;
  color: #01579b;
}
