header {
  position: relative;
}

.header-select .MuiOutlinedInput-root {
  background: #fafafa;
  color: black;
  padding: 2px 10px !important;
  border-radius: 25px;
  font-size: 14px;
}

.header-select .MuiSelect-select {
  padding: 0 !important;
}

.header-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0;
}

.header-select svg {
  display: none;
}

.navbar {
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: -webkit-linear-gradient(-135deg, #0070c9 25%, #72bdf8 100%);
  background-image: -o-linear-gradient(-135deg, #0070c9 25%, #72bdf8 100%);
  background-image: linear-gradient(-135deg, #0070c9 25%, #72bdf8 100%);

  .img {
    height: 45px;
  }
  .links {
    display: flex;
    gap: 2rem;
    .anchor {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.692);
      font-size: 14px;
    }
  }
}
